<template>
  <Body class="ProductSettings" v-if="hasPermission( 'Admin', 'ManageSettings' )">
    <div v-if="settings">
      <div v-for="(setting, settingName) of settingOpts" :key="setting">
        <label>
          <!-- <input type="checkbox" :ref="setting" @click="setSetting(setting, !settings[setting])" :checked="settings[setting]" /> -->
          <input type="checkbox" :ref="setting" @click="changeSetting(setting, !settings[setting])" :checked="settings[setting]" />
          {{ settingName }}
        </label>
      </div>
      <Button @click="setSettings()" :disabled="!changed">Save</Button>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductSettingsView',
  data() {
    return {
      settings: false,
      changed: false,
      settingOpts: {
        'Product Details Extended': 'productDetails',
        'Show list of people on reports': 'showPeopleOnReports',
        Portfolios: 'portfolios',
        'Display all product details by default': 'displayAllProductDetails',
        'Disable spreadsheet prompt in report': 'disableSpreadsheetPrompt',
        'Show report home without requiring a page': 'showReportWithoutPage',
        'Disable publish and share functionality': 'disablePublishAndShare',
        'Disable report preview functionality': 'disablePreviewReport',
        'Disable report accessibility statement functionality': 'disableAccessibilityStatement',
        'Enable CSV exports': 'csvExport',
        'Show visible search labels': 'visibleSearchLabels',
        'Jira Integration Enabled?': 'jiraIntegration',
        'Show Footer with accessibility statement': 'statementLink',
        'Disable add issue button outside of spreadsheet': 'disableAddIssue',
        'Search: split identifiers by dashes': 'splitIdentifierByDashForSearch',
        'Search: do not employ fuzzy search': 'employSubstringNotFuzzySearch',
        'Search: include product url in search': 'includeProductUrlInSearch',
        'Search: sort by letter after search term': 'sortByLetterAfterSearchTerm',
        'Allow option to allow internal team to access a report regardless of which team it is on': 'internalTeamAccess',
        'Disable Darkmode': 'disableDarkMode',
        'Show page and component screenshots': 'showScreenshots',
      },
    };
  },
  apollo: {
    settings: {
      query: gql`
        {
          settings: Settings {
            id
            productDetails,
            portfolios,
            displayAllProductDetails,
            disableSpreadsheetPrompt,
            showReportWithoutPage,
            disablePublishAndShare,
            disablePreviewReport,
            disableAccessibilityStatement,
            showPeopleOnReports,
            csvExport,
            visibleSearchLabels,
            jiraIntegration,
            statementLink,
            disableAddIssue,
            splitIdentifierByDashForSearch,
            employSubstringNotFuzzySearch,
            sortByLetterAfterSearchTerm,
            includeProductUrlInSearch,
            internalTeamAccess,
            disableDarkMode,
            showScreenshots,
          }
        }
      `,
      result( res ) {
        this.settings = { ...res.data.settings };
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    setSetting( setting, enabled ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setSetting($setting: String!, $enabled: Boolean!) {
            settings: setSetting(setting: $setting, enabled: $enabled) {
              id
              productDetails,
              portfolios,
              displayAllProductDetails,
              disableSpreadsheetPrompt,
              showReportWithoutPage,
              disablePublishAndShare,
              disablePreviewReport,
              disableAccessibilityStatement,
              showPeopleOnReports,
              csvExport,
              visibleSearchLabels,
              jiraIntegration,
              statementLink,
              disableAddIssue,
              splitIdentifierByDashForSearch,
              employSubstringNotFuzzySearch,
              sortByLetterAfterSearchTerm,
              includeProductUrlInSearch,
              internalTeamAccess,
              disableDarkMode,
              showScreenshots,
            }
          }
        `,
        variables: {
          setting,
          enabled,
        },
      } ).then( res => {
        this.settings = { ...res.data.settings };
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
    changeSetting( setting, enabled ) {
      this.settings[setting] = enabled;
      this.changed = true;
    },
    setSettings() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setSettings($settings: SettingsInput!) {
            settings: setSettings(settings: $settings) {
              id
              productDetails,
              portfolios,
              displayAllProductDetails,
              disableSpreadsheetPrompt,
              showReportWithoutPage,
              disablePublishAndShare,
              disablePreviewReport,
              disableAccessibilityStatement,
              showPeopleOnReports,
              csvExport,
              visibleSearchLabels,
              jiraIntegration,
              statementLink,
              disableAddIssue,
              splitIdentifierByDashForSearch,
              employSubstringNotFuzzySearch,
              includeProductUrlInSearch,
              sortByLetterAfterSearchTerm,
              internalTeamAccess,
              disableDarkMode,
              showScreenshots
            }
          }
        `,
        variables: {
          // settings: this.settings,
          settings: {
            productDetails: this.settings.productDetails,
            portfolios: this.settings.portfolios,
            displayAllProductDetails: this.settings.displayAllProductDetails,
            disableSpreadsheetPrompt: this.settings.disableSpreadsheetPrompt,
            showReportWithoutPage: this.settings.showReportWithoutPage,
            disablePublishAndShare: this.settings.disablePublishAndShare,
            disablePreviewReport: this.settings.disablePreviewReport,
            disableAccessibilityStatement: this.settings.disableAccessibilityStatement,
            showPeopleOnReports: this.settings.showPeopleOnReports,
            csvExport: this.settings.csvExport,
            visibleSearchLabels: this.settings.visibleSearchLabels,
            jiraIntegration: this.settings.jiraIntegration,
            statementLink: this.settings.statementLink,
            disableAddIssue: this.settings.disableAddIssue,
            splitIdentifierByDashForSearch: this.settings.splitIdentifierByDashForSearch,
            employSubstringNotFuzzySearch: this.settings.employSubstringNotFuzzySearch,
            includeProductUrlInSearch: this.settings.includeProductUrlInSearch,
            sortByLetterAfterSearchTerm: this.settings.sortByLetterAfterSearchTerm,
            internalTeamAccess: this.settings.internalTeamAccess,
            disableDarkMode: this.settings.disableDarkMode,
            showScreenshots: this.settings.showScreenshots,
          },
        },
      } ).then( res => {
        this.$apollo.queries.settings.refetch();
        this.changed = false;
        this.$alerts.success( 'Settings updated', `Application settings have been saved` );
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
  },
};
</script>

<style lang="scss" scoped></style>
